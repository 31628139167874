import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { AppBar, Dialog, IconButton, Toolbar, Typography } from "@mui/material";
import { FC, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ReactSignatureCanvas from "react-signature-canvas";

import { useAppDispatch, useAppSelector } from "store";
import {
  selectSelectedJob,
  selectSelectedJobVisit,
  setSignatureEngineer,
} from "store/slices/jobs.store";

import { StyledContainer } from "components/StyledContainer";
import Transition from "components/Transition";
import engineerSettings from "helpers/engineerSettings";
import { CustomerSignoffComponent } from "./CustomerSignoffComponent";
import { EngineerSignoffComponent } from "./EngineerSignoffComponent";

interface SignoffDialogProps {
  open: boolean;
  handleClose: () => void;
}

export const SignoffDialog: FC<SignoffDialogProps> = (props: SignoffDialogProps) => {
  const { open, handleClose } = props;
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const job = useAppSelector(selectSelectedJob);
  const { skipSignature, customerSignerName, signoffErrors, submitLoading } =
    useAppSelector(selectSelectedJobVisit);

  const [openCustomerReview, setOpenCustomerReview] = useState(false);
  const [isCustomerSignatureAdded, setCustomerSignatureAdded] = useState(false);
  const [isEngineerSignatureAdded, setEngineerSignatureAdded] = useState(false);

  const requireEngineerSignature = engineerSettings.requireEngineerSignature ?? false;
  const requireCustomerSignature = engineerSettings.requireCustomerSignature ?? false;
  const requireCustomerSignatureName = engineerSettings.requireCustomerSignatureName ?? false;

  const canvasRef = useRef<null | ReactSignatureCanvas>(null);
  const canvasRefEngineer = useRef<null | ReactSignatureCanvas>(null);

  const isValid = () => {
    return !Object.keys(signoffErrors).length;
  };

  const onClose = () => {
    handleClose();
    setCustomerSignatureAdded(false);
    setEngineerSignatureAdded(false);
    setOpenCustomerReview(false);
  };

  return (
    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label={intl.formatMessage({ id: "general.back" })}
            size="large"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography className="AppBar-label" paddingRight="36px">
            {openCustomerReview ? (
              <FormattedMessage id="general.customer" />
            ) : (
              <FormattedMessage id="general.engineer" />
            )}{" "}
            / {`${job?.externalId} `} <FormattedMessage id="visit.signoff.summary" />
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <StyledContainer>
        {!openCustomerReview ? (
          <EngineerSignoffComponent
            canvasRef={canvasRefEngineer}
            handleSignature={setEngineerSignatureAdded}
            disableNextButton={
              submitLoading || (requireEngineerSignature && !isEngineerSignatureAdded)
            }
            loading={submitLoading}
            openCustomerReview={() => {
              dispatch(
                setSignatureEngineer({
                  signatureData: canvasRefEngineer.current?.getTrimmedCanvas().toDataURL(),
                })
              );
              setOpenCustomerReview(true);
            }}
          />
        ) : (
          <CustomerSignoffComponent
            canvasRef={canvasRef}
            handleClose={onClose}
            handleSignature={setCustomerSignatureAdded}
            disableCompleteVisitButton={
              submitLoading ||
              (requireCustomerSignatureName && !customerSignerName) ||
              (requireCustomerSignature && !isCustomerSignatureAdded) ||
              (!skipSignature && (!isValid() || !isCustomerSignatureAdded)) ||
              !customerSignerName
            }
            loading={submitLoading}
          />
        )}
      </StyledContainer>
    </Dialog>
  );
};
