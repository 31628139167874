import { CardHeader, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { FormattedMessage, useIntl } from "react-intl";

import { intlFormatPrice, isPartReturnRequested } from "helpers";
import { UsedPart } from "models/usedPart";
import { StockStore } from "operations/schema/schema";
import { useAppSelector } from "store";
import { selectSelectedJobVisit } from "store/slices/jobs.store";

import engineerSettings from "helpers/engineerSettings";
import { TotalTimeComponent } from "../times/TotalTimeComponent";

interface JobVisitSummaryProps {
  isEngineerSummaryPage?: boolean;
}

export const JobVisitSummary = (props: JobVisitSummaryProps) => {
  const { usedParts, workTimes, solutionDescription, travelTimes } =
    useAppSelector(selectSelectedJobVisit);
  const intl = useIntl();
  const allowSalesPrice = engineerSettings.allowSalesPrice ?? false;

  const travelEnd = travelTimes[travelTimes.length - 1]?.stopTime
    ? new Date(travelTimes[travelTimes.length - 1].stopTime!)
    : null;

  const jobUsedParts = usedParts
    .filter(
      (jobPart: UsedPart) =>
        jobPart.part.stockStore !== StockStore.Other && !isPartReturnRequested(jobPart.part)
    )
    .map((jobPart: UsedPart) => {
      return {
        id: jobPart.part.id,
        quantity: jobPart.part.quantity,
        partNumber: jobPart.part.partNumber,
        stockId: jobPart.part.stockId,
        stockStore: jobPart.part.stockStore,
        description: jobPart.part.description,
        storeLabel: jobPart.part.stockStore === StockStore.Nonstock ? jobPart.part.storeLabel : "",
        salesPrice: jobPart.part.salesPrice ?? 0,
      };
    });

  const jobRequestedParts = usedParts
    .filter((jobPart: UsedPart) => jobPart.part.stockStore === StockStore.Other)
    .map((jobPart: UsedPart) => {
      return {
        id: jobPart.part.id,
        quantity: jobPart.part.quantity,
        partNumber: jobPart.part.partNumber,
        stockId: jobPart.part.stockId,
        stockStore: jobPart.part.stockStore,
        description: jobPart.part.description,
        salesPrice: jobPart.part.salesPrice ?? 0,
      };
    });

  const totalSalesPrice =
    jobUsedParts.reduce((sum, jobPart) => sum + jobPart.salesPrice * (jobPart.quantity ?? 0), 0) +
    jobRequestedParts.reduce(
      (sum, jobPart) => sum + jobPart.salesPrice * (jobPart.quantity ?? 0),
      0
    );

  const jobReturnedParts = usedParts
    .filter(
      (jobPart: UsedPart) =>
        jobPart.part.stockStore !== StockStore.Other && isPartReturnRequested(jobPart.part)
    )
    .map((jobPart: UsedPart) => {
      return {
        id: jobPart.part.id,
        quantity: jobPart.part.quantity,
        partNumber: jobPart.part.partNumber,
        stockId: jobPart.part.stockId,
        stockStore: jobPart.part.stockStore,
        description: jobPart.part.description,
        storeLabel: jobPart.part.stockStore === StockStore.Nonstock ? jobPart.part.storeLabel : "",
      };
    });

  return (
    <>
      <Card elevation={1}>
        <CardHeader
          title={intl.formatMessage({ id: "visit.visitInformation" })}
          sx={{ paddingBottom: "0px" }}
        />
        <CardContent sx={{ paddingTop: "0px" }}>
          <List dense>
            {travelEnd !== null && (
              <ListItem key="Travel time">
                <ListItemText
                  key="Travel time text"
                  primaryTypographyProps={{ variant: "body1" }}
                  secondaryTypographyProps={{
                    variant: "subtitle2",
                    color: "textPrimary",
                  }}
                  primary={`${intl.formatMessage({ id: "times.travelTime" })}:`}
                  secondary={<TotalTimeComponent timesMerged={travelTimes} />}
                />
              </ListItem>
            )}
            <ListItem key="Work time">
              <ListItemText
                key="Work time text"
                primaryTypographyProps={{ variant: "body1" }}
                secondaryTypographyProps={{
                  variant: "subtitle2",
                  color: "textPrimary",
                }}
                primary={`${intl.formatMessage({ id: "times.workTime" })}:`}
                secondary={<TotalTimeComponent timesMerged={workTimes} />}
              />
            </ListItem>
            {solutionDescription !== "" && (
              <ListItem key="Solution">
                <ListItemText
                  key="Solution text"
                  primaryTypographyProps={{ variant: "body1" }}
                  secondaryTypographyProps={{
                    variant: "subtitle2",
                    color: "textPrimary",
                  }}
                  primary={`${intl.formatMessage({
                    id: "job.solutionDescription",
                  })}:`}
                  secondary={solutionDescription}
                />
              </ListItem>
            )}
            {allowSalesPrice && jobUsedParts?.length > 0 && (
              <ListItem>
                <strong>
                  <FormattedMessage id="visit.totalPrice" />
                </strong>
                : {intlFormatPrice(intl, totalSalesPrice)}
              </ListItem>
            )}
            {jobUsedParts.length > 0 && (
              <ListItem key="visit.usedParts">
                <ListItemText
                  key="Used parts text"
                  primaryTypographyProps={{ variant: "body1" }}
                  secondaryTypographyProps={{
                    variant: "subtitle2",
                    color: "textPrimary",
                  }}
                  primary={`${intl.formatMessage({ id: "visit.usedParts" })}:`}
                  secondary={jobUsedParts.map((usedPart, index) => {
                    return (
                      <div key={index}>
                        <div className="truncated">
                          {`${usedPart.quantity} x ${usedPart.description}${
                            usedPart.storeLabel ? ` (${usedPart.storeLabel})` : ""
                          }`}
                        </div>
                        {allowSalesPrice && usedPart.salesPrice && (
                          <Typography variant="body2" color="textSecondary">
                            <FormattedMessage id="part.salesPrice" />:{" "}
                            {intlFormatPrice(intl, usedPart.salesPrice)}
                          </Typography>
                        )}
                      </div>
                    );
                  })}
                />
              </ListItem>
            )}
            {jobRequestedParts.length > 0 && (
              <ListItem key="Requested parts">
                <ListItemText
                  key="Requested parts text"
                  primaryTypographyProps={{ variant: "body1" }}
                  secondaryTypographyProps={{
                    variant: "subtitle2",
                    color: "textPrimary",
                  }}
                  primary={`${intl.formatMessage({ id: "part.requestedParts" })}:`}
                  secondary={jobRequestedParts.map((requestedPart, index) => (
                    <div key={index}>
                      <div className="truncated">
                        {`${requestedPart.quantity} x ${requestedPart.description}`}
                      </div>
                      {allowSalesPrice && requestedPart.salesPrice && (
                        <Typography variant="body2" color="textSecondary">
                          <FormattedMessage id="part.salesPrice" />:{" "}
                          {intlFormatPrice(intl, requestedPart.salesPrice)}
                        </Typography>
                      )}
                    </div>
                  ))}
                />
              </ListItem>
            )}
            {jobReturnedParts.length > 0 && (
              <ListItem key="Returned parts">
                <ListItemText
                  key="Returned parts text"
                  primaryTypographyProps={{ variant: "body1" }}
                  secondaryTypographyProps={{
                    variant: "subtitle2",
                    color: "textPrimary",
                  }}
                  primary={`${intl.formatMessage({ id: "part.returnedParts" })}:`}
                  secondary={jobReturnedParts.map((returnedParts, index) => (
                    <div className="truncated" key={index}>
                      {`${returnedParts.quantity} x ${returnedParts.description}`}
                    </div>
                  ))}
                />
              </ListItem>
            )}
          </List>
        </CardContent>
      </Card>
    </>
  );
};
