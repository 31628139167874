import { Autocomplete, Container, FormHelperText, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FC, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { ExtraInputType, ExtraType } from "operations/schema/schema";
import { useAppDispatch, useAppSelector } from "store";
import { addExtra } from "store/slices/jobs.store";

import { AsolviDatePicker } from "components/AsolviDatePicker";
import { AsolviSwitch } from "components/AsolviSwitch";
import { AsolviTimePicker } from "components/AsolviTimePicker";
import PrimaryButton from "components/PrimaryButton";
import SecondaryButton from "components/SecondaryButton";
import StyledTextField from "components/StyledTextField";
import { DefinedDate, toDate, toDateString } from "helpers";
import { ExtraValidationSchema } from "models/ExtraValidationSchema";
import { ValidationError } from "yup";

const PREFIX = "AddExtra";

const classes = {
  flex: `${PREFIX}-flex`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(2),

  [`& .${classes.flex}`]: {
    marginTop: theme.spacing(2),
    display: "flex",
  },
}));

interface AddExtraProps {
  setOpenAddExtraDialog: (open: boolean) => void;
  loading: boolean;
}

export const AddExtra: FC<AddExtraProps> = (props) => {
  const { loading, setOpenAddExtraDialog } = props;
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { extras } = useAppSelector((state) => state.cache);
  const [errors, setErrors] = useState<any>({});
  const [selectedCode, setSelectedCode] = useState<String | null>(null);
  const [selectedExtra, setSelectedExtra] = useState<ExtraType | null>(null);
  const [isUseTime, setIsUseTime] = useState<boolean>(false);
  const [startTime, setStartTime] = useState<DefinedDate>(toDateString(new Date()));
  const [stopTime, setStopTime] = useState<DefinedDate>(toDateString(new Date()));
  const [quantity, setQuantity] = useState<number>(0);
  const regExpDecimal = new RegExp(`^-?\\d*.+\\d*$`);

  return (
    <StyledContainer data-testid="AddPartDialog">
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Autocomplete
            fullWidth
            loading={loading}
            options={[...new Set(extras.map((e) => e.code))]}
            renderInput={(params) => (
              <StyledTextField {...params} label={intl.formatMessage({ id: "visit.extra.code" })} />
            )}
            value={selectedCode}
            data-testid="AddExtraDialog-Code"
            onChange={(_, value) => {
              setSelectedCode(value);
              setSelectedExtra(null);
            }}
            noOptionsText={intl.formatMessage({
              id: "general.noOptions",
            })}
          />
        </Grid>
        <Grid item>
          <Autocomplete
            fullWidth
            loading={loading}
            options={extras.filter((e) => !!selectedCode && e.code === selectedCode)}
            getOptionLabel={(e) => e.type}
            renderInput={(params) => (
              <StyledTextField {...params} label={intl.formatMessage({ id: "visit.extra.type" })} />
            )}
            value={selectedExtra}
            data-testid="AddExtraDialog-Type"
            onChange={(_, value) => {
              setSelectedExtra(value);
              setIsUseTime(value!.isTime);
            }}
            noOptionsText={intl.formatMessage({
              id: "general.noOptions",
            })}
          />
        </Grid>
        {!!selectedExtra && (
          <Grid
            item
            container
            alignItems="center"
            justifyContent="space-between"
            sx={{ pt: "10px", pb: "10px" }}
          >
            <Grid item sx={{ pl: "8px" }}>
              <Typography variant="body1">
                <FormattedMessage id="visit.extra.useTime" />
              </Typography>
            </Grid>
            <Grid item>
              <AsolviSwitch
                key="ExtrasSwitch"
                data-testid="AddExtras-Toggle"
                checked={isUseTime}
                onClick={() => {
                  setIsUseTime(!isUseTime);
                }}
              />
            </Grid>
          </Grid>
        )}
        {!!selectedExtra && isUseTime && (
          <>
            <Grid item container direction="row" spacing={1} wrap="nowrap">
              <Grid item xs={6}>
                <AsolviDatePicker
                  error={errors?.startDate}
                  name={`NewExtraStartDate`}
                  label={intl.formatMessage({ id: "times.startDate" })}
                  required
                  value={toDate(startTime)}
                  onChange={(value: Date) => setStartTime(toDateString(value))}
                />
              </Grid>
              <Grid item xs={6}>
                <AsolviTimePicker
                  error={errors?.startTime}
                  name={`NewExtraStartTime`}
                  label={intl.formatMessage({ id: "times.startTime" })}
                  required
                  value={toDate(startTime)}
                  onChange={(value: Date) => setStartTime(toDateString(value))}
                />
              </Grid>
            </Grid>
            <Grid item container direction="row" spacing={1} wrap="nowrap">
              <Grid item xs={6}>
                <AsolviDatePicker
                  error={errors?.stopDate}
                  name={`NewExtraStopDate`}
                  label={intl.formatMessage({ id: "visit.endDate" })}
                  required
                  value={toDate(stopTime)}
                  onChange={(value: Date) => setStopTime(toDateString(value))}
                />
              </Grid>
              <Grid item xs={6}>
                <AsolviTimePicker
                  error={errors?.stopTime}
                  name={`NewExtraStartTime`}
                  label={intl.formatMessage({ id: "visit.endTime" })}
                  required
                  value={toDate(stopTime)}
                  onChange={(value: Date) => setStopTime(toDateString(value))}
                />
              </Grid>
            </Grid>
          </>
        )}
        {!!selectedExtra && !isUseTime && (
          <>
            <Grid item>
              <Grid item xs={12}>
                <AsolviDatePicker
                  error={errors?.startDate}
                  name={`startDate`}
                  label={intl.formatMessage({ id: "times.startDate" })}
                  required
                  value={toDate(startTime)}
                  onChange={(value: Date) => setStartTime(toDateString(value))}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                error={!!errors?.quantity}
                inputProps={{ step: "any" }}
                type="number"
                name="quantity"
                label={intl.formatMessage({ id: "general.quantity" })}
                value={quantity}
                onChange={(e: any) => {
                  const { value } = e.target;

                  if (regExpDecimal.test(value) || value === "") {
                    setQuantity(Number(value));
                  }
                }}
              />
              <FormHelperText error>{errors?.quantity}</FormHelperText>
            </Grid>
          </>
        )}
        <Grid item>
          <PrimaryButton
            key={`SaveExtraButton`}
            disabled={!selectedExtra}
            onClick={() => {
              const newExtra: ExtraInputType = {
                id: selectedExtra?.id || "",
                type: selectedExtra?.type || "",
                isTime: isUseTime,
                startTime: toDateString(startTime),
                stopTime: isUseTime ? toDateString(stopTime) : null,
                quantity,
                servicesCode: selectedExtra?.servicesCode || "",
              };

              const validationSchema = ExtraValidationSchema();
              const isValid = validationSchema.isValidSync(newExtra);
              if (isValid) {
                dispatch(addExtra({ extra: newExtra }));
                setErrors({});
                setSelectedExtra(null);
                setQuantity(0);
              } else {
                try {
                  validationSchema.validateSync(newExtra, { strict: true, abortEarly: false });
                } catch (e: any) {
                  let { inner } = e as ValidationError;
                  let errors: any = {};
                  for (let error of inner) {
                    if (!error.path) continue;
                    errors[error.path] = error.message;
                  }
                  setErrors({ ...errors });
                }
              }
            }}
          >
            <FormattedMessage id="visit.addExtra" />
          </PrimaryButton>
        </Grid>
        <Grid item>
          <SecondaryButton
            variant="contained"
            onClick={() => setOpenAddExtraDialog(false)}
            data-testid="AddExtraDialogFinishButton"
          >
            <FormattedMessage id="general.finish" />
          </SecondaryButton>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};
